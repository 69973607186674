@font-face {
  font-family: Saol Display;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("SaolDisplay-Regular.ae00b519.woff2") format("woff2"), url("SaolDisplay-Regular.d4899111.woff") format("woff");
}

@font-face {
  font-family: Recoleta;
  src: url("Recoleta-RegularDEMO.739dca58.woff2") format("woff2"), url("Recoleta-RegularDEMO.56a8de72.woff") format("woff"), url("Recoleta-RegularDEMO.f52d2d44.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Founders Grotesk;
  src: url("FoundersGrotesk-Regular.7f8e9836.woff2") format("woff2"), url("FoundersGrotesk-Regular.59d76547.woff") format("woff"), url("FoundersGrotesk-Regular.24cc72b2.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Canela Text;
  src: url("CanelaText-Thin.63abf522.woff2") format("woff2"), url("CanelaText-Thin.03246617.woff") format("woff"), url("CanelaText-Thin.2e6b1b34.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

:root {
  --color-black: #000;
  --color-grey-darker: #3e3f3f;
  --color-grey-dark: #3e3f3f;
  --color-grey: #f7f5f4;
  --color-white: #fff;
  --menu: #000;
  --font-f-serif: "Canela Text";
  --font-f-sans-light: "Canela Text";
  --font-f-sans: "Founders Grotesk";
  --font-s-heading-sm: 8.333vw;
  --lh-heading-sm: 10.556vw;
  --font-s-chapeau-md: 3.444vw;
  --lh-chapeau-md: 8.167vw;
  --font-s-chapeau-sm: 2.611vw;
  --lh-chapeau-sm: 2.611vw;
  --font-s-body: 4.444vw;
  --lh-body: 8.333vw;
  --ease-button: cubic-bezier(.34, 0, .18, 1);
  --spacing-15: 4.11vw;
  --spacing-30: 8.219vw;
  --spacing-45: 12.329vw;
  --spacing-60: 16.438vw;
  --spacing-75: 20vw;
  --spacing-90: 24.658vw;
  --spacing-120: 32.877vw;
  --spacing-150: 40vw;
  --spacing-180: 49.315vw;
  --col-1: calc(100vw / 12 * 1);
  --col-2: calc(100vw / 12 * 2);
  --col-3: calc(100vw / 12 * 3);
  --col-4: calc(100vw / 12 * 4);
  --col-5: calc(100vw / 12 * 5);
  --col-6: calc(100vw / 12 * 6);
  --col-7: calc(100vw / 12 * 7);
  --col-8: calc(100vw / 12 * 8);
  --col-9: calc(100vw / 12 * 9);
  --col-10: calc(100vw / 12 * 10);
  --col-11: calc(100vw / 12 * 11);
  --col-12: calc(100vw / 12 * 12);
}

@media (width >= 768px) {
  :root {
    --font-s-heading-sm: 3.472vw;
    --lh-heading-sm: 4.583vw;
    --font-s-chapeau-md: 1.25vw;
    --lh-chapeau-md: 1.597vw;
    --font-s-chapeau-sm: .903vw;
    --lh-chapeau-sm: .903vw;
    --font-s-body: 1.111vw;
    --lh-body: 2.083vw;
    --spacing-15: 1.042vw;
    --spacing-30: 2.083vw;
    --spacing-45: 3.125vw;
    --spacing-60: 4.167vw;
    --spacing-75: 5.208vw;
    --spacing-90: 6.25vw;
    --spacing-120: 8.333vw;
    --spacing-150: 10.417vw;
    --spacing-180: 12.5vw;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

body, h1, h2, h3, h4, p, li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body {
  text-rendering: optimizeSpeed;
  text-size-adjust: 100%;
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
  margin: 0;
  line-height: 1.5;
  overflow-y: scroll;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

button {
  cursor: pointer;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
  appearance: none;
  background: none;
  border: 0;
  outline: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--color-grey-darker);
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
  box-shadow: none;
  border-radius: 0;
  outline: none;
}

select {
  -webkit-appearance: none;
  background: none;
  border: none;
}

[style*="--aspect-ratio"] > :first-child, .cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
    overflow: hidden;
  }

  [style*="--aspect-ratio"]:before {
    content: "";
    padding-bottom: calc(100% / var(--aspect-ratio));
    display: block;
  }
}

html, body {
  height: 100%;
}

body {
  margin: 0;
}

.app {
  background-color: var(--color-grey);
  position: fixed;
  inset: 0;
}

.is-device .navigation-wrapper {
  -webkit-overflow-scrolling: touch;
  position: fixed;
  inset: 0;
  overflow-y: scroll;
}

body {
  font-family: var(--font-f-sans);
  font-size: var(--font-s-body);
  line-height: var(--lh-body);
  color: var(--color-white);
  font-weight: 400;
}

.pov {
  color: #000;
  grid-template-columns: repeat(12, 1fr);
  place-items: start center;
  gap: 1em 14px;
  display: grid;
  position: relative;
}

.pov .content__heading {
  text-align: center;
}

.pov.is-centered {
  align-items: center;
}

.pov .socialproof {
  grid-area: 2 / 5 / 2 / 9;
  justify-self: center;
  padding-top: 3em;
  font-size: 14px;
  display: flex;
}

.pov img {
  align-self: center;
  width: 100px;
  display: flex;
}

.client1 {
  grid-area: 3 / 3 / 3 / 5;
}

.client2 {
  grid-area: 3 / 5 / 3 / 7;
}

.client3 {
  grid-area: 3 / 7 / 3 / 9;
}

.client4 {
  grid-area: 3 / 9 / 3 / 11;
}

@media (width >= 768px) {
  .pov .socialproof {
    grid-column: 5 / 8;
  }

  .pov .content__heading {
    text-align: left;
  }

  .client1 {
    grid-area: 3 / 3 / 3 / 4;
  }

  .client2 {
    grid-area: 3 / 7 / 3 / 8;
  }

  .client3 {
    grid-area: 3 / 5 / 3 / 6;
  }

  .client4 {
    grid-area: 3 / 9 / 3 / 10;
  }
}

button.button-slider-open:after {
  content: url("bng-black.1d6564ad.svg");
  color: var(--menu);
  width: 110px;
  font-family: Founders Grotesk;
  font-size: 14px;
  position: absolute;
  top: 30%;
  left: 110%;
}

body.dark button.button-slider-open:after {
  content: url("bng-logo-white.42d51d6e.svg");
}

.button {
  height: 11.111vw;
  font-size: var(--font-s-button);
  font-size: 12px;
  line-height: var(--lh-button);
  text-transform: uppercase;
  align-items: center;
  margin: 8.333vw 0 0;
  text-decoration: none;
  display: flex;
}

.button__icon {
  pointer-events: none;
  justify-content: center;
  align-items: center;
  width: 11.111vw;
  height: 11.111vw;
  margin: 0 0 0 4.167vw;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.button__circle {
  background-color: var(--color-white);
  border-radius: 50%;
  width: 11.111vw;
  height: 11.111vw;
  position: absolute;
  overflow: visible;
}

.button__svg {
  fill: none;
  width: 4.278vw;
  height: 1.092vw;
  stroke: var(--color-grey-darker);
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: .5px;
  display: block;
  position: absolute;
}

.button__title--secondary {
  display: none;
}

@media (width >= 768px) {
  .button {
    height: 2.778vw;
    font-size: 18px;
  }

  .button__icon {
    width: 2.778vw;
    height: 2.778vw;
    margin: 0 0 0 1.042vw;
  }

  .button__circle {
    width: 2.778vw;
    height: 2.778vw;
  }

  .button__svg {
    width: 1.069vw;
    height: .273vw;
  }

  .button__svg--clone {
    transform-origin: 0;
    transform: translateX(-2.778vw);
  }

  .button__title-mask {
    padding: .347vw 0;
    position: relative;
    overflow: hidden;
  }

  .button__title {
    transform-origin: 100%;
    display: block;
    transform: rotate(0);
  }

  .button__title--secondary {
    transform-origin: 0;
    display: block;
    position: absolute;
    bottom: -2.778vw;
    left: 0;
    transform: rotate(15deg);
  }
}

@media (hover: hover) {
  .button__icon > .button__svg--clone {
    transition: transform .3s var(--ease-button);
  }

  .button__icon, .button__icon > .button__svg, .button__title-mask > .button__title-wrap, .button__title-mask > .button__title-wrap > .button__title, .button__title-mask > .button__title-wrap > .button__title--secondary {
    will-change: transform;
    transition: transform .62s var(--ease-button);
  }

  .button:hover > .button__title-mask > .button__title-wrap {
    transform: translateY(-2.778vw);
  }

  .button:hover > .button__title-mask > .button__title-wrap > .button__title {
    transform: rotate(15deg);
  }

  .button:hover > .button__title-mask > .button__title-wrap > .button__title--secondary {
    transform: rotate(0);
  }

  .button:hover > .button__icon > .button__svg {
    opacity: 0;
    transition: .3s var(--ease-button);
    transition-property: opacity, transform;
    transform: translateX(2.778vw);
  }

  .button:hover > .button__icon > .button__svg--clone {
    opacity: 1;
    transition: transform .62s var(--ease-button);
    transform: translateX(0);
  }
}

.button.is-white {
  color: var(--color-white);
}

.dark {
  background-color: #000;
}

.logo {
  max-width: 200px;
  padding-top: 20px;
}

a-scene {
  position: absolute;
}

section.content {
  height: 100%;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.a-dialog-text {
  color: #000;
}

sup {
  font-size: .5em;
}

.tbc {
  pointer-events: none;
  cursor: wait;
}

.frame svg {
  width: 150px;
  fill: var(--menu);
}

.app main {
  height: 100%;
}

.navigation-frame > a {
  width: 125px;
  display: inline-block;
  position: relative;
  top: 50px;
  left: 50px;
}

@media screen and (width >= 1080px) {
  body {
    color: #000;
  }

  body.dark a {
    color: #fff;
  }

  a {
    color: #000;
  }

  a-scene {
    display: none;
  }

  #bng-logo {
    fill: #000;
    max-width: 250px;
  }
}

.dark .button-slider-open svg {
  fill: #fff;
}

.button-slider-open {
  top: var(--spacing-15);
  left: var(--spacing-15);
  z-index: 2;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 16.667vw;
  height: 16.667vw;
  display: flex;
  position: fixed;
}

.button-slider-open__icon {
  width: 2.689vw;
  height: 3.228vw;
  fill: var(--menu);
}

@media (hover: hover) {
  .button-slider-open {
    transform-origin: center;
    will-change: transform;
  }

  .button-slider-open__icon {
    overflow: visible;
  }

  .button-slider-open__icon-square {
    will-change: transform;
    transition: all .4s cubic-bezier(.8, .16, .41, .86);
  }

  .button-slider-open:hover .button-slider-open__icon-square:first-of-type {
    transform: translate(-.1vw, .1vw);
  }

  .button-slider-open:hover .button-slider-open__icon-square:nth-of-type(2) {
    transform: translate(0, .1vw);
  }

  .button-slider-open:hover .button-slider-open__icon-square:nth-of-type(3) {
    transform: translate(.1vw, .1vw);
  }

  .button-slider-open:hover .button-slider-open__icon-square:nth-of-type(4) {
    transform: translate(-.1vw);
  }

  .button-slider-open:hover .button-slider-open__icon-square:nth-of-type(5) {
    transform: translate(0);
  }

  .button-slider-open:hover .button-slider-open__icon-square:nth-of-type(6) {
    transform: translate(.1vw);
  }

  .button-slider-open:hover .button-slider-open__icon-square:nth-of-type(7) {
    transform: translate(-.1vw, -.1vw);
  }

  .button-slider-open:hover .button-slider-open__icon-square:nth-of-type(8) {
    transform: translate(0, -.1vw);
  }

  .button-slider-open:hover .button-slider-open__icon-square:nth-of-type(9) {
    transform: translate(.1vw, -.1vw);
  }
}

@media (width >= 768px) {
  .button-slider-open {
    backface-visibility: hidden;
    width: 4.167vw;
    height: 4.167vw;
  }

  .button-slider-open__icon {
    width: .7392vw;
    height: .8877vw;
  }
}

.content {
  min-height: calc(var(--vh, 1vh) * 100);
  align-items: center;
  display: flex;
}

.content__subheadings {
  margin: 0 0 0 var(--spacing-15);
  grid-column: 1 / 13;
}

@media (width >= 768px) {
  .content__subheadings {
    margin: 0 0 0 var(--spacing-30);
    grid-column: 1 / 3;
  }
}

.content__subheading {
  text-transform: uppercase;
  display: block;
}

.content__subheading.is-sm {
  margin: var(--spacing-15) 0 0;
  font-size: var(--font-s-chapeau-sm);
  line-height: var(--lh-chapeau-sm);
}

.content__subheading.is-md {
  font-size: var(--font-s-chapeau-md);
  line-height: var(--lh-chapeau-md);
}

.content__heading {
  margin: var(--spacing-30) var(--spacing-15) 0;
  font-family: var(--font-f-sans-light);
  font-size: var(--font-s-heading-sm);
  line-height: var(--lh-heading-sm);
  grid-column: 1 / 13;
}

@media (width >= 768px) {
  .content__heading {
    grid-column: 3 / 10;
    margin: 0;
    display: flex;
  }
}

.content--outro {
  border-top: 1px solid #c8c8c8;
  max-width: 90%;
  min-height: auto;
  margin: 0 auto;
  padding: 0;
}

.placeholders {
  top: 40%;
  right: calc(var(--col-2) * -1.5);
  z-index: 2;
  width: var(--col-9);
  overflow: unset;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  position: fixed;
}

.placeholders__img-wrap {
  width: var(--col-9);
  transform-origin: 0 100%;
  will-change: transform;
  position: absolute;
  top: 0;
  left: 0;
}

.placeholders__img-wrap:first-child {
  z-index: 2;
  top: 10px;
  left: -20px;
}

.placeholders__img-wrap:nth-child(2) {
  z-index: 1;
  top: -30px;
  left: 10px;
}

.placeholders__img-wrap:nth-child(3) {
  z-index: 0;
  top: 30px;
}

.placeholders__img, .placeholders__video {
  object-fit: cover;
  will-change: transform;
  width: 100%;
  height: 100%;
}

@media (width >= 768px) {
  .placeholders {
    width: var(--col-4);
    top: 10%;
  }

  .placeholders__img-wrap {
    width: var(--col-4);
  }

  .placeholders__img, .placeholders__video {
    max-width: unset;
    will-change: transform;
    width: calc(100% + var(--col-3)) !important;
  }
}

.slider {
  --slidesTotal: 4;
  z-index: 9;
  height: calc(var(--vh, 1vh) * 100);
  visibility: hidden;
  opacity: 0;
  will-change: transform;
  background-color: #fff0;
  position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
}

.slider__button-close {
  mix-blend-mode: exclusion;
  z-index: 1001;
  will-change: transform;
  width: 56.944vw;
  height: 56.944vw;
  position: fixed;
  top: -11.1111vw;
  left: 56.944vw;
}

.slider__button-close-icon {
  width: 56.944vw;
  height: 56.944vw;
  overflow: visible;
}

.slider__container {
  grid-template-columns: repeat(var(--slidesTotal), var(--col-9));
  grid-column-gap: 4.167vw;
  height: calc(var(--vh, 1vh) * 100);
  align-items: center;
  padding: 0 4.167vw;
  display: grid;
}

.slider__item {
  pointer-events: none;
  will-change: transform;
  display: flex;
}

.slider__item-img-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.slider__item-img, .slider__item-video {
  object-fit: cover;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  transform-origin: 0;
  will-change: transform;
  width: 100%;
  height: 100%;
}

.slider__item-content {
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  grid-template-rows: 25% 50% 25%;
  grid-template-columns: 50% 50%;
  width: 100%;
  padding: 1em;
  display: grid;
  position: relative;
}

.slider__item-heading-wrap, .slider__item-button-wrap {
  justify-content: center;
  display: flex;
  overflow: hidden;
}

.slider__item-heading-wrap.description {
  text-align: right;
  grid-area: 1 / 1 / 1 / 3;
  justify-self: end;
}

.slider__item-heading-wrap.description .slider__item-heading {
  text-transform: uppercase;
}

.slider__item-heading-wrap.clever {
  grid-area: 2 / 1 / 2 / 1;
  place-self: center start;
}

.slider__item-heading-wrap.product-name {
  grid-area: 2 / 2 / 2 / 2;
  place-self: center end;
}

.slider__item-button-wrap {
  pointer-events: all;
  grid-area: 3 / 1 / 3 / 3;
  place-self: end;
}

.slider__item-heading {
  pointer-events: none;
  font-family: var(--font-f-serif);
  color: var(--color-white);
  text-transform: capitalize;
  transform-origin: 0 100%;
  will-change: transform;
  pointer-events: none;
  font-size: 12px;
  line-height: 10.278vw;
  font-family: var(--font-f-sans);
  text-transform: initial;
  will-change: transform;
  font-size: 12px;
  line-height: 18px;
}

.slider__item-button {
  color: var(--color-white);
  margin: 4.167vw auto 0;
}

@media (width >= 768px) {
  .slider {
    width: 100%;
    height: 100vh;
    overflow-x: unset;
    align-items: center;
    font-size: 18px;
    display: flex;
    position: fixed;
  }

  .slider__button-close {
    top: 1.042vw;
    left: 83.6809vw;
    right: unset;
    position: absolute;
  }

  .slider__button-close, .slider__button-close-icon {
    width: 14.236vw;
    height: 14.236vw;
  }

  .slider__button-close-icon {
    position: absolute;
    top: 0;
    left: 0;
  }

  .slider__container {
    grid-template-columns: repeat(var(--slidesTotal), var(--col-4));
    grid-column-gap: 4.167vw;
    padding: 0 var(--col-1);
    height: unset;
    cursor: grab;
  }

  .slider__item-heading {
    font-size: 18px;
    line-height: 24px;
  }

  .slider__item-button {
    margin: 1.389vw auto;
  }

  .slider__item-img, .slider__item-video {
    max-width: unset;
    pointer-events: none;
    -webkit-user-select: none;
    user-select: none;
    transform-origin: 0;
    will-change: transform;
    transform: scale(1.75);
  }

  .slider__progress-wrap {
    left: var(--col-2);
    width: var(--col-8);
    transform-origin: 0;
    will-change: transform;
    background-color: #d6d6d6;
    height: 1px;
    display: block;
    position: absolute;
    bottom: 3.125vw;
    overflow: hidden;
  }

  .slider__progress {
    background-color: var(--color-black);
    transform-origin: 0;
    will-change: transform;
    width: 100%;
    height: 1px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
  }
}

.is-dragging .slider__container {
  cursor: grabbing;
}

.js-contacts {
  text-align: center;
  text-transform: lowercase;
  grid-template-columns: repeat(4, 1fr);
  width: 80vw;
  font-size: 16px;
  display: grid;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.is-device .js-contacts {
  text-align: center;
  text-transform: lowercase;
  width: 100vw;
  transform: unset;
  grid-template-columns: repeat(4, 1fr);
  margin: 0 auto;
  font-size: 16px;
  display: grid;
  position: absolute;
  bottom: 25px;
  left: 0;
}

.is-device .slider {
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  inset: 0;
  overflow-x: scroll;
  display: nonee !important;
}

.is-device .slider__container {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: scroll;
}

.is-device .slider .nav__button-close {
  position: fixed;
}

.is-device .slider .slider__progress-wrap {
  display: none;
}
/*# sourceMappingURL=index.css.map */
